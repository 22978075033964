import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`100ft Backwards Sled Pull (M-135/W-90)`}</p>
    <p>{`20-S2OH (95/65)`}</p>
    <p>{`15-Pullups`}</p>
    <p>{`20-KBS’s (53/35)`}</p>
    <p>{`15-Back Extension`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Open starts this Thursday, February 22nd.  Sign up now
at Games.CrossFit.com and be sure to add yourself to team CrossFit the
Ville!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start February 24th.  For more info
email Eric at Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      